import { styled } from "solid-styled-components";
import { theme } from "const/theme";
import { JSX } from "solid-js";
import { LangA } from "utils/router";

interface CleanProps {
    padding?: boolean;
    overlay?: boolean;
}

export const CleanA = styled(LangA)<CleanProps>([
    {
        all: "unset",
        cursor: "pointer",
        "& > *, &": {
            transition: "all 0.2s ease-out",
        },
        "&:hover > *, &": {
            color: theme.colors.mainLight,
        },
        "&:hover > *": {
            fill: theme.colors.mainLight,
        },
    },
    ({ padding }) => (padding ? { padding: "0.25em 0.5em" } : {}),
    ({ overlay }) => (overlay ? { width: "100%", height: "100%", position: "absolute" } : {}),
]);

export const CleanButton = styled("button")<CleanProps>([
    {
        all: "unset",
        cursor: "pointer",
        "& > *, &": {
            transition: "all 0.2s ease-out",
        },
        "&:hover > *, &": {
            color: theme.colors.mainLight,
        },
        "&:hover > *": {
            fill: theme.colors.mainLight,
        },
    },
    ({ padding }) => (padding ? { padding: "0.25em 0.5em" } : {}),
]);
