import { styled } from "solid-styled-components";
import { theme } from "const/theme";
import { Property } from "csstype";


export type BaseDivProps = {
    padding?: boolean | string;
    paddingLR?: boolean | string;
    paddingTB?: boolean | string;

    fullHeight?: boolean;
    fullWidth?: boolean;

    height?: string;
    width?: string;

    backgroundColor?: keyof typeof theme.colors;
    flex?: string;

    position?: "relative" | "absolute";

    gridColumn?: Property.GridColumn;
    gridRow?: Property.GridRow;
};

export const BaseDiv = styled("div")<BaseDivProps>([
    ({ padding, paddingLR, paddingTB }) => {
        if (paddingLR) return { padding: `0px ${typeof paddingLR == "boolean" ? theme.spacing.gap.normal : paddingLR}` };
        if (paddingTB) return { padding: `${typeof paddingTB == "boolean" ? theme.spacing.gap.normal : paddingTB} 0px` };
        if (padding) return { padding: typeof padding == "boolean" ? theme.spacing.gap.normal : padding };
        else return {};
    },
    ({ fullHeight, height }) => (height ? { height: height } : fullHeight ? { height: "100%" } : {}),
    ({ fullWidth, width }) => (width ? { height: width } : fullWidth ? { width: "100%" } : {}),
    ({ flex }) => (flex ? { flex: flex } : {}),
    ({ gridColumn }) => (gridColumn ? { gridColumn: gridColumn } : {}),
    ({ gridRow }) => (gridRow ? { gridRow: gridRow } : {}),
    ({ position }) => (position ? { position: position } : {}),
    ({ backgroundColor }) => (backgroundColor ? { backgroundColor: theme.colors[backgroundColor] } : {}),
]);

export type FlexDivProps = {
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "stretch" | "start" | "end" | "baseline";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    alignContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "stretch" | "start" | "end" | "baseline";
    rowGap?: keyof typeof theme.spacing.gap | boolean;
    columnGap?: keyof typeof theme.spacing.gap | boolean;
    gap?: keyof typeof theme.spacing.gap | boolean;
    wrap?: boolean;
};

const FlexParentDiv = styled(BaseDiv)<FlexDivProps>([
    ({ wrap }) => (wrap ? { flexWrap: "wrap" } : { flexWrap: "nowrap" }),
    ({ justifyContent }) => (justifyContent ? { justifyContent: justifyContent } : {}),
    ({ alignContent }) => (alignContent ? { alignContent: alignContent } : {}),
    ({ alignItems }) => (alignItems ? { alignItems: alignItems } : {}),
    ({ rowGap }) => (rowGap ? { rowGap: typeof rowGap == "string" ? rowGap : theme.spacing.gap.normal } : {}),
    ({ columnGap }) => (columnGap ? { columnGap: typeof columnGap == "string" ? columnGap : theme.spacing.gap.normal } : {}),
    ({ gap }) => (gap ? { gap: typeof gap == "string" ? theme.spacing.gap[gap] ?? gap : theme.spacing.gap.normal } : {}),
]);

export const StyledColumns = styled(FlexParentDiv)`
    display: flex;
    flex-direction: row;
`;
export const StyledRows = styled(FlexParentDiv)`
    display: flex;
    flex-direction: column;
`;

export const FlexAuto = styled(BaseDiv)`
    flex: ${(props) => (props.flex ? props.flex : "1 1 0px")};
`;


export type StyledGridProps = {
    gridColumns?: Property.GridTemplateColumns[];
    gridColumnsMobile?: Property.GridTemplateColumns[];
    gridRows?: Property.GridTemplateRows[];
    gridRowsMobile?: Property.GridTemplateRows[];
    alignItems?: "start" | "end" | "center" | "stretch";
    justifyItems?: "start" | "end" | "center" | "stretch";
    gap?: keyof typeof theme.spacing.gap | boolean;
    rowGap?: keyof typeof theme.spacing.gap | boolean;
    columnGap?: keyof typeof theme.spacing.gap | boolean;
};

export const StyledGrid = styled(BaseDiv)<StyledGridProps>([
    { display: "grid" },
    ({ gridColumns }) => (gridColumns ? { gridTemplateColumns: gridColumns.join(" ") } : {}),
    ({ gridRows }) => (gridRows ? { gridTemplateRows: gridRows.join(" ") } : {}),
    ({ gap }) => (gap ? { gap: typeof gap == "string" ? theme.spacing.gap[gap] : theme.spacing.gap.normal } : {}),
    ({ rowGap }) => (rowGap ? { rowGap: typeof rowGap == "string" ? theme.spacing.gap[rowGap] : theme.spacing.gap.normal } : {}),
    ({ columnGap }) => (columnGap ? { columnGap: typeof columnGap == "string" ? theme.spacing.gap[columnGap] : theme.spacing.gap.normal } : {}),
    ({ alignItems }) => (alignItems ? { alignItems: alignItems } : ""),
    ({ justifyItems }) => (justifyItems ? { justifyItems: justifyItems } : ""),
    (props) => ({
        [`@media (max-width: ${theme.spacing.page.mobile})`]: {
            gridTemplateColumns: props.gridColumnsMobile ? props.gridColumnsMobile.join(" ") : undefined,
            gridTemplateRows: props.gridRowsMobile ? props.gridRowsMobile.join(" ") : undefined,
        },
    }),
]);
