import { createSignal, type Component, type ComponentProps, type JSX, onMount, createMemo, untrack } from "solid-js";
import { isServer } from "solid-js/web";

export default function clientOnly<T extends Component<any>>(fn: T): (props: ComponentProps<T>) => JSX.Element {
    if (isServer) return () => null;

    //@ts-ignore
    return (props: ComponentProps<T>) => {
        let m: boolean;
        const [mounted, setMounted] = createSignal(false);
        onMount(() => setMounted(true));
        return createMemo(() => ((m = mounted()), untrack(() => (m ? fn(props) : null))));
    };
}
