import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { createEffect, createMemo, mergeProps, Show, VoidComponent, JSX } from "solid-js";

import { getStyles, getTransform } from "./utils";
import { css } from "solid-styled-components";

const spinStyle = css`
    animation: spin 1s 0s infinite linear;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const pulseStyle = css`
    animation: spin 1s infinite steps(8);
`;

interface SolidFaProps {
    icon: IconDefinition;

    size?: "xs" | "sm" | "lg" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
    color?: string;

    fw?: boolean;
    pull?: "left" | "right";

    scale?: number | string;
    translateX?: number | string;
    translateY?: number | string;
    rotate?: number | string;
    flip?: "horizontal" | "vertical" | "both";

    spin?: boolean;
    pulse?: boolean;

    class?: string;
    style?: JSX.CSSProperties;
    classList?:
        | {
              [k: string]: boolean | undefined;
          }
        | undefined;
}

const Fa: VoidComponent<SolidFaProps> = (props) => {
    props = mergeProps(
        {
            scale: 1,
            translateX: 0,
            translateY: 0,
        },
        props
    );

    const i = createMemo(() => props.icon?.icon || [0, 0, "", [], ""]);
    const s = createMemo(() => getStyles(props.size, props.pull, props.fw));
    const transform = createMemo(() => getTransform(props.scale, props.translateX, props.translateY, props.rotate, props.flip, 512));

    return (
        <svg
            classList={{
                [spinStyle]: props.spin,
                [pulseStyle]: props.pulse,
                ...props.classList,
            }}
            class={props.class}
            style={{...props.style, ...s()}}
            viewBox={`0 0 ${i()[0]} ${i()[1]}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g transform={`translate(${i()[0] / 2} ${i()[1] / 2})`} transform-origin={`${i()[0] / 4} 0`}>
                <g transform={transform()}>
                    <Show
                        when={typeof i()[4] === "string"}
                        fallback={
                            <>
                                <path d={i()[4][0]} fill={props.color} transform={`translate(${i()[0] / -2} ${i()[1] / -2})`} />
                                <path d={i()[4][1]} fill={props.color} transform={`translate(${i()[0] / -2} ${i()[1] / -2})`} />
                            </>
                        }
                    >
                        <path d={i()[4] as string} fill={props.color} transform={`translate(${i()[0] / -2} ${i()[1] / -2})`} />
                    </Show>
                </g>
            </g>
        </svg>
    );
};

export default Fa;
