import { Router } from "@solidjs/router";
import { MetaProvider } from "@solidjs/meta";
import { routes } from "./routes";


export default (props: { url?: string; tags?: any[] }) => {
    return (
        <MetaProvider>
            <Router url={props.url}>
                {routes}
            </Router>
        </MetaProvider>
    );
};
