import { createSignal } from "solid-js";
import { ICookieConfig } from "./config";
import { CrossStorageClient } from "cross-storage";
import { isServer } from "solid-js/web";

var storage: null | CrossStorageClient = null;

async function getStorage() {
    if (storage == null) {
        var split = window.location.host.split(".");
        storage = new CrossStorageClient(
            window.location.protocol +
                "//" +
                (split[split.length - 2] ? split[split.length - 2] + "." : "") +
                split[split.length - 1] +
                "/cookieHub/cookieHub",
            {}
        );
    }
    await storage.onConnect();
    return storage;
}

export async function getConsent() {
    if (!isServer) {
        const lc = await (await getStorage()).get("cookie_consent").catch(() => null);
        if (lc != null) return JSON.parse(lc);
        else return {};
    } else return {};
}

export async function checkCookieConsent() {
    const lc = await (await getStorage()).get("cookie_consent").catch(() => null);
    return lc == null;
}

export async function setCookieConsent(consent: any, config: ICookieConfig) {
    const obj: any = { ...consent, Necessary: {} };
    Object.keys(config["Necessary"]).forEach((key) => {
        obj.Necessary[key] = 1;
    });
    await (await getStorage()).set("cookie_consent", JSON.stringify(obj));
    executeCookieScripts(config);
    let [, setCookieConsentNeed] = cookieConsentNeedSignal;
    setCookieConsentNeed(false);
}

export async function executeCookieScripts(config: ICookieConfig, ignoreRevoke?: boolean) {
    const consent = await getConsent();
    console.groupCollapsed("Applying cookies");
    console.log("Consent Cookie: %O", consent);
    console.log("Cookie config: %O", config);
    Object.keys(config).forEach((key) => {
        Object.keys(config[key]).forEach((name) => {
            let service = config[key][name];
            if (consent[key] && consent[key][name] && service.insertCallback) {
                console.log('Applied cookie "%s" in group "%s"', name, key);
                service.insertCallback();
            } else {
                if (ignoreRevoke != true && service.revokeCallback) {
                    console.log("Revoked cookie %s in group %s", name, key);
                    service.revokeCallback();
                }
            }
        });
    });
    console.groupEnd();
}

export async function allowAllCookies(config: ICookieConfig) {
    const consent: any = {};
    Object.keys(config).forEach((key) => {
        consent[key] = {};
        Object.keys(config[key]).forEach((name) => {
            consent[key][name] = 1;
            let service = config[key][name];
            if (service.insertCallback) service.insertCallback();
        });
    });
    await (await getStorage()).set("cookie_consent", JSON.stringify(consent));
    let [, setCookieConsentNeed] = cookieConsentNeedSignal;
    setCookieConsentNeed(false);
}

export async function revokeAllCookies(config: ICookieConfig) {
    await (await getStorage()).del("cookie_consent");
    executeCookieScripts(config);
    let [, setCookieConsentNeed] = cookieConsentNeedSignal;
    setCookieConsentNeed(true);
}

export const cookieConsentNeedSignal = createSignal(false);
