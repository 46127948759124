import { styled } from "solid-styled-components";
import { theme } from "const/theme";

type TextProps = {
    color?: keyof typeof theme.colors;
    textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
    clickable?: boolean;
    fitContent?: boolean;
    fontWeight?: "bold" | "normal" | "light" | "bolder";
    fontFamily?: "WorkSans";
    size?: keyof typeof theme.fonts.size;
};

export const P1 = styled("p")<TextProps>([
    { fontWeight: 400 },
    ({ color }) => (color ? { color: theme.colors[color] } : {}),
    ({ fitContent }) => (fitContent ? { width: "fit-content" } : {}),
    ({ textAlign }) => (textAlign ? { textAlign: textAlign } : {}),
    ({ fontWeight }) => (fontWeight ? { fontWeight: fontWeight } : {}),
    ({ clickable }) => (clickable ? { cursor: "pointer", userSelect: "none" } : {}),
    ({ fontFamily }) => (fontFamily ? { fontFamily: fontFamily } : {}),
    ({ size }) => (size ? { fontSize: theme.fonts.size[size] } : {}),
]);

export const P2 = styled("p")<TextProps>([
    { fontWeight: 400, fontSize: theme.fonts.size.small },
    ({ color }) => (color ? { color: theme.colors[color] } : {}),
    ({ fitContent }) => (fitContent ? { width: "fit-content" } : {}),
    ({ textAlign }) => (textAlign ? { textAlign: textAlign } : {}),
    ({ fontWeight }) => (fontWeight ? { fontWeight: fontWeight } : {}),
    ({ clickable }) => (clickable ? { cursor: "pointer", userSelect: "none" } : {}),
    ({ fontFamily }) => (fontFamily ? { fontFamily: fontFamily } : {}),
    ({ size }) => (size ? { fontSize: theme.fonts.size[size] } : {}),
]);

export const H1 = styled("h1")<TextProps>([
    ({ color }) => (color ? { color: theme.colors[color] } : {}),
    ({ fitContent }) => (fitContent ? { width: "fit-content" } : {}),
    ({ textAlign }) => (textAlign ? { textAlign: textAlign } : {}),
    ({ fontWeight }) => (fontWeight ? { fontWeight: fontWeight } : {}),
    ({ clickable }) => (clickable ? { cursor: "pointer", userSelect: "none" } : {}),
    ({ fontFamily }) => (fontFamily ? { fontFamily: fontFamily } : {}),
    ({ size }) => (size ? { fontSize: theme.fonts.size[size] } : {}),
]);

export const H2 = styled("h2")<TextProps>([
    ({ color }) => (color ? { color: theme.colors[color] } : {}),
    ({ fitContent }) => (fitContent ? { width: "fit-content" } : {}),
    ({ textAlign }) => (textAlign ? { textAlign: textAlign } : {}),
    ({ fontWeight }) => (fontWeight ? { fontWeight: fontWeight } : {}),
    ({ clickable }) => (clickable ? { cursor: "pointer", userSelect: "none" } : {}),
    ({ fontFamily }) => (fontFamily ? { fontFamily: fontFamily } : {}),
    ({ size }) => (size ? { fontSize: theme.fonts.size[size] } : {}),
]);

export const H3 = styled("h3")<TextProps>([
    ({ color }) => (color ? { color: theme.colors[color] } : {}),
    ({ fitContent }) => (fitContent ? { width: "fit-content" } : {}),
    ({ textAlign }) => (textAlign ? { textAlign: textAlign } : {}),
    ({ fontWeight }) => (fontWeight ? { fontWeight: fontWeight } : {}),
    ({ clickable }) => (clickable ? { cursor: "pointer", userSelect: "none" } : {}),
    ({ fontFamily }) => (fontFamily ? { fontFamily: fontFamily } : {}),
    ({ size }) => (size ? { fontSize: theme.fonts.size[size] } : {}),
]);
