import { A } from "@solidjs/router";
import { theme } from "const/theme";
import { showCookieDetails } from "cookieConsent/state";
import { css } from "solid-styled-components";
import { FlexAuto, StyledColumns } from "styles/layout";
import { P2 } from "styles/text";
import { createTextObject } from "utils/content";
import getText from "text/shared/footer";
import { CleanButton } from "styles/interface";

const FooterStyle = css({
    maxWidth: theme.spacing.page.header,
    padding: `${theme.spacing.gap.normal} ${theme.spacing.gap.normal}`,
});

const FooterContainerStyle = css({
    borderTop: `2px solid ${theme.colors.brighter}`,
    backgroundColor: "white",
    marginTop: "auto",
});

const Component = () => {
    const text = createTextObject(getText);
    return (
        <>
            <StyledColumns justifyContent="center" class={FooterContainerStyle}>
                <StyledColumns class={FooterStyle} flex="1 1 0px" justifyContent="space-between" gap>
                    <A href="/privacy">
                        <P2>{text()?.privacy}</P2>
                    </A>
                    <A href="/terms">
                        <P2>{text()?.terms}</P2>
                    </A>
                    <A href="/imprint">
                        <P2>{text()?.imprint}</P2>
                    </A>

                    <CleanButton>
                        <P2 color="main" onClick={() => showCookieDetails()}>
                            {text()?.cookie_settings}
                        </P2>
                    </CleanButton>
                </StyledColumns>
            </StyledColumns>
        </>
    );
};

export default Component;
