import { theme } from "const/theme";
import { styled } from "solid-styled-components";

interface ButtonProps {
    secondary?: boolean;
    danger?: boolean;
    clean?: boolean;
    color?: keyof typeof theme.colors;
    textColor?: keyof typeof theme.colors;
}

const StyledButton = styled("button")<ButtonProps>([
    ({ color, textColor }) => ({
        transition: "all 0.2s ease-out",
        padding: "0.25em 0.5em",
        cursor: "pointer",
        borderRadius: theme.radius.default,
        backgroundColor: color ? theme.colors[color] : theme.colors.main,
        border: "2px solid " + (color ? theme.colors[color] : theme.colors.main),
        color: textColor ? theme.colors[textColor] : theme.colors.white,
        "&:disabled": {
            border: `2px solid ${theme.colors.brighter}`,
            color: theme.colors.dark,
            backgroundColor: theme.colors.bright,
            cursor: "unset",
        },
        "&:hover:enabled > *, &:hover:enabled": {
            backgroundColor: theme.colors.mainLight,
            border: "2px solid " + theme.colors.mainLight,
            color: "white",
        },
    }),
    ({ secondary }) =>
        secondary
            ? {
                  background: theme.colors.white,
                  color: theme.colors.black,
                  "&:hover": {
                      border: "2px solid " + theme.colors.mainLight,
                  },
              }
            : "",
    ({ danger }) => (danger ? { background: theme.colors.error, color: theme.colors.white, border: `2px solid ${theme.colors.error}` } : ""),
]);

export default StyledButton;
