import { isServer } from "solid-js/web";

export function loadScript(src: string, id?: string) {
    return new Promise(function (resolve, reject) {
        var s;
        s = document.createElement("script");
        s.type = "text/javascript";
        s.src = src;
        s.id = id ?? "";
        s.onload = resolve;
        s.onerror = reject;
        s.async = false;
        document.head.appendChild(s);
    });
}

export function iterCookie() {
    var keyValuePairs = document.cookie.split(";");
    let arr: { name: string; value: string }[] = [];
    for (let i = 0; i < keyValuePairs.length; i++) {
        let name = keyValuePairs[i].substring(0, keyValuePairs[i].indexOf("=")).replace(" ", "");
        let value = keyValuePairs[i].substring(keyValuePairs[i].indexOf("=") + 1);
        arr.push({ name, value });
    }
    return arr;
}

export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function setCookie(name: string, value: string, days?: number, domain?: string) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    } else {
        expires = "; expires=Thu, 01 Jan 1970 00:00:00 GMT"
    }
    let domainString = ""
    if (domain) {
        domainString = "; Domain=" + domain
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/" + domainString;
}


export function getParentDomain(subLevel?: string) {
    if (isServer) return "https://" + (subLevel ? subLevel + "." : "") + "sustainallite.com";
    var split = window.location.host.split(".");
    return (
        window.location.protocol +
        "//" +
        (subLevel ? subLevel + "." : "") +
        (split[split.length - 2] ? split[split.length - 2] + "." : "") +
        split[split.length - 1]
    );
}

export const email_regex =
    /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export async function awaitTimeout(time: number) {
    let prom = new Promise((res) => {
        setTimeout(() => res(undefined), time);
    });
    await prom;
}
