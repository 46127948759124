import { faClose } from "@fortawesome/free-solid-svg-icons";
import { theme } from "const/theme";
import { Accessor, Component, JSX, Setter, createEffect, createSignal, onCleanup, splitProps } from "solid-js";
import { css, styled } from "solid-styled-components";
import Fa from "styles/fa";
import { BaseDiv, FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import { P1 } from "styles/text";
import { Portal } from "solid-js/web";
import { CleanButton } from "./clean";

const BackdropStyle = css`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 99999;
    backdrop-filter: blur(10px);
`;

const StyledDiv = styled("div")`
    max-height: calc(100vh - 25px);
    max-width: calc(100vw - 25px);
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.bright};
    border-radius: ${theme.radius.default};
    & > :first-child {
        border-top-left-radius: ${theme.radius.default};
        border-top-right-radius: ${theme.radius.default};
    }
    & > :last-child {
        border-bottom-left-radius: ${theme.radius.default};
        border-bottom-right-radius: ${theme.radius.default};
    }
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
`;

export interface StyledDialogElement {
    showModal: () => void;
    close: () => void;
    open: Accessor<boolean>;
}

export const Dialog: Component<JSX.HTMLAttributes<HTMLDivElement> & { ref?: Setter<StyledDialogElement | undefined>; onClose?: () => void }> = (props) => {
    const [container, setContainer] = createSignal<HTMLDivElement>();
    const [open, setOpen] = createSignal(false);
    const instance = {
        showModal: () => {
            let cont = container();
            if (cont) {
                setOpen(true);
                cont.style.display = "unset";
                setTimeout(() => {
                    cont!.style.opacity = "1";
                }, 100);
            }
        },
        close: () => {
            let cont = container();
            if (cont) {
                setOpen(false);
                cont.style.opacity = "0";
                setTimeout(() => {
                    cont!.style.display = "none";
                }, 300);
            }
            if (props.onClose) props.onClose();
        },
        open,
    };
    const [local, others] = splitProps(props, ["ref"]);

    createEffect(() => {
        if (local.ref) local.ref(instance);
    });

    return (
        <Portal mount={document.body}>
            <div class={BackdropStyle} ref={setContainer}>
                <StyledDiv {...others}>
                    <StyledColumns
                        backgroundColor="white"
                        style={{ position: "sticky", top: "0px", "border-bottom": `1px solid ${theme.colors.bright}` }}
                        gap="small"
                        padding
                    >
                        <P1>{props.title}</P1>
                        <FlexAuto></FlexAuto>
                        <CleanButton onClick={() => instance.close()}>
                            <Fa icon={faClose}></Fa>
                        </CleanButton>
                    </StyledColumns>
                    {props.children}
                </StyledDiv>
            </div>
        </Portal>
    );
};

export default Dialog;
