import { css, styled } from "solid-styled-components";
import { theme } from "const/theme";
import { JSX } from "solid-js";
import { BaseDiv } from "styles/layout";
import { LangA } from "utils/router";

export { default as StyledSelect } from "./select";
export { default as StyledButton } from "./button";
export { default as StyledDatePicker } from "./datePicker";
export { default as StyledInput } from "./input";
export { default as StyledPassword } from "./password";
export { default as StyledSlider } from "./slider";
export { default as StyledTextArea } from "./textArea";
export { default as StyledDialog } from "./dialog";
export { default as StyledCheckbox } from "./checkbox";
export * from "./clean";

const StyledSwitchSlider = styled("span")`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.bright};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
`;

const StyledSwitchInput = styled("input")`
    outline: none;

    &:checked + span {
        background-color: ${theme.colors.main};
    }

    &:checked + span:before {
        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
    }
`;

const StyledSwitchLabel = styled("label")`
    position: relative;
    display: inline-block;
    width: 48px;
    height: 20px;
    cursor: pointer;

    &.disabled {
        cursor: unset;
    }

    & input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

export function StyledSwitch(props: {
    disabled?: boolean;
    checked?: boolean;
    onChange?: JSX.ChangeEventHandlerUnion<HTMLInputElement, Event>;
    onClick?: JSX.EventHandlerUnion<HTMLInputElement, MouseEvent>;
}) {
    return (
        <StyledSwitchLabel classList={{ disabled: props.disabled }}>
            <StyledSwitchInput type="checkbox" {...props}></StyledSwitchInput>
            <StyledSwitchSlider></StyledSwitchSlider>
        </StyledSwitchLabel>
    );
}

export const HorizontalSpacer = styled(BaseDiv)({
    height: "3px",
    backgroundColor: theme.colors.brighter,
    borderRadius: "10px",
    width: "100%",
});