import { iterCookie, loadScript, setCookie } from "utils";

export interface ICookieConfig {
    [group: string]: {
        [serviceName: string]: {
            description: { [lang: string]: string };
            title: { [lang: string]: string };
            infoLink?: string;
            cookies: { name: string; purpose?: { [lang: string]: string }; expiryDays?: number; location?: "localStorage" | "cookie" | "indexDB" }[];
            insertCallback?: () => void;
            revokeCallback?: () => void;
        };
    };
}

export const cookieConfig: ICookieConfig = {
    Necessary: {
        cookie_consent: {
            title: { "": "Cookie-Einstellungen", en: "Cookie consent" },
            description: {
                "": "Speichert deine Cookie-Einstellungen damit du sie nicht immer wieder eingeben musst.",
                en: "Stores your consent so the cookie banner does not show up every time.",
            },
            cookies: [
                {
                    name: "checked_cookie_consent",
                    purpose: { "": "Speichert deine Cookie-Einstellungen.", en: "This cookie stores information about your cookie consent." },
                    expiryDays: 0,
                    location: "localStorage",
                },
                {
                    name: "youtube_consent",
                    purpose: {
                        "": "Speichert ob du YouTube-Videos erlaubt hast.",
                        en: "This cookie is present when you allowed us to display youtube content",
                    },
                    expiryDays: 0,
                    location: "localStorage",
                },
            ],
        },
    },
    Analytics: {
        google_analytics: {
            description: { en: "Helps us to understand where our website visitors come from.", "": "Hilft uns zu verstehen woher unsere Nutzer kommen." },
            title: { "": "Google Analytics" },
            infoLink: "https://policies.google.com/privacy",
            cookies: [
                {
                    name: "_ga",
                    purpose: {
                        "": "Speichert einen einzigartigen Wert um Nutzer zwischen Webseiten zu identifizieren.",
                        en: "This cookie stores a unique identifier to identify user across websites.",
                    },
                    expiryDays: 730,
                },
                {
                    name: "_ga_<container-id>",
                    purpose: {
                        "": "Speichert den Session Status.",
                        en: "Used to persist session state.",
                    },
                    expiryDays: 730,
                },
            ],
            async insertCallback() {
                try {
                    await loadScript("https://www.googletagmanager.com/gtag/js?id=G-CLQC7KW8VH", "_ga");
                    //@ts-ignore
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        //@ts-ignore
                        dataLayer.push(arguments);
                    }
                    //@ts-ignore
                    gtag("js", new Date());

                    //@ts-ignore
                    gtag("config", "G-CLQC7KW8VH");
                } catch (error) {
                    console.error(error);
                }
            },
            revokeCallback() {
                let arr = iterCookie();
                
                arr.forEach((el) => {
                    console.log(el)
                    if (el.name.startsWith("_ga")) setCookie(el.name, "", undefined, "." + window.location.hostname);
                });
            },
        },
    },
};
