import { Route, Router, useLocation, useMatch } from "@solidjs/router";
import { createEffect, createMemo, JSX, Suspense } from "solid-js";
import { HydrationScript } from "solid-js/web";
import { GlobalStyles } from "styles/global";
import { MetaProvider, Title, Link, Meta, useHead } from "@solidjs/meta";
import Header from "homepage/components/header";
import Footer from "./footer";
import CookieConsent from "cookieConsent";
import { languages } from "const";
import { langFilter } from "utils/router";

export const Skeleton = (props: {children?: JSX.Element}) => {
    const location = useLocation();
    const match = useMatch(() => "/:lang/*", langFilter);


    return (
        <html lang="en">
            <head>
                <Title>Sustainallite</Title>

                {/** Technical meta */}
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/** Youtube scripts */}
                <script>
                    {`const e=t=>{t.forEach((t=>{if(0!=t.childNodes.length&&e(t.childNodes),"IFRAME"==t.nodeName&&null==localStorage.getItem("youtube-consent")&&t.src.includes("youtube")){const e=t.src;t.src="";const n=document.createElement("div"),o=document.createElement("input");o.type="button",o.value="Agree",o.addEventListener("click",(()=>{localStorage.setItem("youtube-consent","1"),t.src=e})),o.style="padding: 10px; background-color: #ff9747; border: none; border-radius: 5px; color: black;",n.innerHTML='<p style="text-align: center; word-break: normal;">Playing this video requires sharing information with YouTube.<br><a target="_blank" href="https://tosdr.org/#youtube">More information</a></p>',n.appendChild(o),n.style="height: 100vh; width: 100vw; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 10px; background-color: #EDEDED; border-radius: 5px; overflow-y: auto; box-sizing: border-box",n.className=t.className,t.addEventListener("load",(e=>{t.contentDocument.body.appendChild(n),t.contentDocument.body.style="all: unset;"}))}}))};document.addEventListener("DOMContentLoaded",(function(t){const n=document.getElementsByTagName("iframe");for(let t=0;t<n.length;t++){const o=n[t];e([o])}MutationObserver=window.MutationObserver||window.WebKitMutationObserver,new MutationObserver((function(t){t.forEach((function(t){e(t.addedNodes)}))})).observe(document.body,{childList:!0,subtree:!0})}));`}
                </script>

                <HydrationScript />

                <link rel="stylesheet" href="/rollup/bundle.css"></link>

                {/** Fonts css because goober only uses last font type */}
                <link rel="stylesheet" href="/assets/css/font-face.min.css"></link>

                {/** Style tag for styled components */}
                <style id="_goober"></style>
                <GlobalStyles></GlobalStyles>

                {/** hreflang */}
                {languages.map((lang) => {
                    const cMatch = match();
                    let href = location.pathname;
                    if (cMatch) {
                        href = href.slice(3);
                    }
                    if (lang) {
                        href = "/" + lang + href;
                    }

                    return <link rel="alternate" hreflang={lang == "" ? "x-default" : lang} href={href}></link>;
                })}
            </head>
            <body>
                <Suspense>
                    <Header></Header>
                </Suspense>
                
                <Suspense>
                    {props.children}
                </Suspense>

                <Suspense>
                    <Footer></Footer>
                </Suspense>
                <Suspense>
                    <CookieConsent></CookieConsent>
                </Suspense>

                <script type="module" src={process.env.CLIENT_BUNDLE} async></script>
                {/** Fixes issue for sticky elements that are child of body on chrome */}
                {/** https://www.stevefenton.co.uk/blog/2022/12/mobile-position-sticky-issue/ */}
                <div style={{ position: "fixed" }}></div>
            </body>
        </html>
    );
};
