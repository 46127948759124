import { theme } from "const/theme";
import { Accessor, createComputed, createEffect, createMemo, createResource, createSignal, For, Show, Suspense } from "solid-js";
import { render } from "solid-js/web";
import { HorizontalSpacer, StyledButton, StyledDialog, StyledSwitch } from "styles/interface";
import { FlexAuto, StyledColumns, StyledRows, BaseDiv } from "styles/layout";
import { H2, H3, P1 } from "styles/text";
import { cookieConfig, ICookieConfig } from "./config";
import { allowAllCookies, cookieConsentNeedSignal, getConsent, setCookieConsent } from "cookieConsent/functions";
import getText from "text/shared/cookies";
import { createTextObject } from "utils/content";
import { cookieDetailsTrigger, setCookieDetailsTrigger } from "./state";
import { useParams } from "@solidjs/router";

export const CookieDetails = (props: { config: ICookieConfig }) => {
    const text = createTextObject(getText);
    let [dialog, setDialog] = createSignal<HTMLDialogElement>();
    let trigger = cookieDetailsTrigger;
    const [consent, { mutate: setConsent, refetch }] = createResource(getConsent);

    const params = useParams();

    const lang = createMemo(() => {
        return params.lang;
    });

    createEffect(() => {
        if (trigger()) {
            dialog()?.showModal();
        }
    });

    return (
        <StyledDialog ref={setDialog} title={text()?.details.title} style={{ "min-height": "60%", display: "flex", "flex-direction": "column" }}>
            <StyledRows flex="1 1">
                <Suspense fallback={<div style={{ "max-width": "600px", width: "100vw" }}></div>}>
                    <StyledColumns style={{ "max-width": "600px" }}>
                        <StyledRows flex="1 1 0px" padding gap style={{ width: "100vw" }}>
                            {Object.keys(props.config).map((key) => {
                                const [expand, setExpand] = createSignal(false);
                                const allChildrenAllowed = createMemo(() => {
                                    if (consent())
                                        return Object.keys(props.config[key]).reduce((prev, curr, i) => {
                                            if (prev == false && i != 0) return prev;
                                            else {
                                                if (consent()[key] && consent()[key][curr]) return true;
                                                else return false;
                                            }
                                        }, false);
                                    else return false;
                                });

                                return (
                                    <StyledRows gap>
                                        <StyledColumns alignItems="center">
                                            <H2 style={{ cursor: "pointer" }} onClick={() => setExpand(!expand())}>
                                                {key}
                                            </H2>
                                            <FlexAuto></FlexAuto>
                                            <StyledSwitch
                                                checked={key == "Necessary" || allChildrenAllowed()}
                                                disabled={key == "Necessary"}
                                                onChange={(ev) => {
                                                    if (ev.target.checked) {
                                                        let obj: Dictionary<number> = {};
                                                        Object.keys(props.config[key]).forEach((k) => {
                                                            obj[k] = 1;
                                                        });
                                                        setConsent({ ...consent(), [key]: obj });
                                                    } else {
                                                        setConsent({ ...consent(), [key]: {} });
                                                    }
                                                }}
                                            ></StyledSwitch>
                                        </StyledColumns>
                                        <Show when={expand()}>
                                            <HorizontalSpacer></HorizontalSpacer>
                                            <StyledRows gap>
                                                {Object.keys(props.config[key]).map((name) => {
                                                    const data = props.config[key][name];
                                                    const [cookieExpand, setCookieExpand] = createSignal(false);

                                                    return (
                                                        <StyledRows gap="small">
                                                            <StyledColumns alignItems="center">
                                                                <H3>{data.title[lang()] || data.title[""]}</H3> <FlexAuto></FlexAuto>{" "}
                                                                <StyledSwitch
                                                                    checked={key == "Necessary" || (consent()[key] && consent()[key][name])}
                                                                    disabled={key == "Necessary"}
                                                                    onChange={(ev) => {
                                                                        let con = consent();
                                                                        if (ev.target.checked) {
                                                                            if (con[key] == undefined) con[key] = {};
                                                                            con[key][name] = 1;
                                                                        } else {
                                                                            if (con[key]) {
                                                                                delete con[key][name];
                                                                            }
                                                                        }
                                                                        setConsent({ ...con });
                                                                    }}
                                                                ></StyledSwitch>
                                                            </StyledColumns>
                                                            <P1>
                                                                {data.description[lang()] || data.description[""]}
                                                                <Show when={data.infoLink}>
                                                                    {" "}
                                                                    <a href={data.infoLink} style={{ color: theme.colors.main, "user-select": "none" }}>
                                                                        {text()?.details.more_info}
                                                                    </a>
                                                                </Show>
                                                            </P1>
                                                            <P1
                                                                style={{ "user-select": "none", color: theme.colors.main, cursor: "pointer" }}
                                                                onClick={() => setCookieExpand(!cookieExpand())}
                                                            >
                                                                {cookieExpand() ? text()?.details.details_hide : text()?.details.details_show}
                                                            </P1>

                                                            <Show when={cookieExpand()}>
                                                                <StyledRows gap padding backgroundColor="brighter" style={{ "border-radius": "10px" }}>
                                                                    <For each={data.cookies}>
                                                                        {(cookie, index) => {
                                                                            return (
                                                                                <StyledRows gap>
                                                                                    <P1>
                                                                                        {text()?.details.name} {cookie.name}
                                                                                    </P1>
                                                                                    <Show when={cookie.purpose}>
                                                                                        <P1>{cookie.purpose![lang()] || cookie.purpose![""]}</P1>
                                                                                    </Show>
                                                                                    <Show when={cookie.expiryDays}>
                                                                                        <P1>
                                                                                            {text()?.details.expires} {cookie.expiryDays} {text()?.details.days}
                                                                                        </P1>
                                                                                    </Show>
                                                                                    <Show when={cookie.location}>
                                                                                        <P1>
                                                                                            {text()?.details.location} {cookie.location}
                                                                                        </P1>
                                                                                    </Show>
                                                                                    <Show when={index() + 1 != data.cookies.length}>
                                                                                        <HorizontalSpacer></HorizontalSpacer>
                                                                                    </Show>
                                                                                </StyledRows>
                                                                            );
                                                                        }}
                                                                    </For>
                                                                </StyledRows>
                                                            </Show>
                                                        </StyledRows>
                                                    );
                                                })}
                                            </StyledRows>
                                        </Show>

                                        <HorizontalSpacer></HorizontalSpacer>
                                    </StyledRows>
                                );
                            })}
                        </StyledRows>
                    </StyledColumns>
                </Suspense>
                <FlexAuto></FlexAuto>
                <StyledColumns backgroundColor="white" gap padding style={{ position: "sticky", bottom: "0" }} justifyContent="space-between">
                    <StyledButton
                        onClick={() => {
                            setCookieConsent(consent(), props.config);
                            refetch();
                            dialog()?.close();
                        }}
                        secondary
                    >
                        {text()?.details.accept_selected}
                    </StyledButton>
                    <StyledButton
                        onClick={() => {
                            allowAllCookies(props.config);
                            refetch();
                            dialog()?.close();
                        }}
                        secondary
                    >
                        {text()?.banner.allow_all}
                    </StyledButton>
                    <StyledButton
                        onClick={() => {
                            setCookieConsent({}, props.config);
                            refetch();
                            dialog()?.close();
                        }}
                    >
                        {text()?.banner.allow_necessary}
                    </StyledButton>
                </StyledColumns>
            </StyledRows>
        </StyledDialog>
    );
};
