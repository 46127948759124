import { theme } from "const/theme";
import { createEffect, createSignal } from "solid-js";
import { isServer, render } from "solid-js/web";
import { StyledButton, StyledDialog } from "styles/interface";
import { FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import { H3, P1 } from "styles/text";
import { CookieDetails } from "./details";
import { cookieConfig } from "./config";
import { allowAllCookies, checkCookieConsent, cookieConsentNeedSignal, executeCookieScripts, setCookieConsent } from "./functions";
import { createTextObject } from "utils/content";
import getText from "text/shared/cookies";
import { showCookieDetails } from "./state";
import clientOnly from "utils/clientOnly";

const Container = () => {
    const text = createTextObject(getText);
    let [cookieConsentNeed, setCookieConsentNeed] = cookieConsentNeedSignal;

    let [display, setDisplay] = createSignal(false);

    createEffect(async () => {
        if (!isServer) {
            if (await checkCookieConsent()) {
                setCookieConsentNeed(true);
            } else {
                executeCookieScripts(cookieConfig, true);
            }
        }
    });

    createEffect(() => {
        if (!isServer) {
            if (cookieConsentNeed()) {
                setDisplay(true);
            } else {
                setDisplay(false);
            }
        }
    });

    return (
        <>
            <StyledRows
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                style={{ position: "sticky", bottom: "0", width: "100%", display: display() ? "" : "none" }}
                backgroundColor="bright"
            >
                <StyledRows padding style={{ "max-width": theme.spacing.page.max }} gap>
                    <H3>{text()?.banner.title}</H3>
                    <P1>{text()?.banner.note}</P1>
                    <StyledColumns backgroundColor="bright" gap>
                        <StyledButton onClick={() => setCookieConsent({}, cookieConfig)}>{text()?.banner.allow_necessary}</StyledButton>
                        <StyledButton onClick={() => allowAllCookies(cookieConfig)}>{text()?.banner.allow_all}</StyledButton>
                        <FlexAuto></FlexAuto>
                        <StyledButton onClick={() => showCookieDetails()}>{text()?.banner.configure}</StyledButton>
                    </StyledColumns>
                </StyledRows>
            </StyledRows>
            <CookieDetails config={cookieConfig}></CookieDetails>
        </>
    );
};

export default Container;
