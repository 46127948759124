import { RouteDefinition } from "@solidjs/router/dist/types";
import { lazy } from "solid-js";
import { MetaProvider } from "@solidjs/meta";
import { Skeleton } from "./skeleton";
import { langFilter } from "utils/router";

// We have to wrap every route in the MetaProvider because there is a issue with context passing
// https://github.com/stevej2608/solid-realworld/commit/00b328f596874f3e994b41c72388b4307063df6d

const Home = lazy(() => import("homepage/components/singlePages/home"));
const NotFound = lazy(() => import("homepage/components/singlePages/notFound"));
const AboutMe = lazy(() => import("homepage/components/singlePages/aboutMe"));
const Talents = lazy(() => import("homepage/components/products/talents"));
const ShowBlog = lazy(() => import("homepage/components/knowledge/blog/showBlog"));
const Blog = lazy(() => import("homepage/components/knowledge/blog"));
const Glossary = lazy(() => import("homepage/components/knowledge/glossary"));
const Terms = lazy(() => import("homepage/components/singlePages/terms"));
const Imprint = lazy(() => import("homepage/components/singlePages/imprint"));
const Privacy = lazy(() => import("homepage/components/singlePages/privacy"));

export const routes: RouteDefinition[] = [
    {
        path: "/:lang?/*",
        matchFilters: langFilter,
        component: Skeleton,
        children: [
            // Single pages
            {
                path: "/privacy",
                component: () => (
                    <MetaProvider>
                        <Privacy></Privacy>
                    </MetaProvider>
                ),
            },
            {
                path: "/imprint",
                component: () => (
                    <MetaProvider>
                        <Imprint></Imprint>
                    </MetaProvider>
                ),
            },
            {
                path: "/terms",
                component: () => (
                    <MetaProvider>
                        <Terms></Terms>
                    </MetaProvider>
                ),
            },
            //{ path: "/contact", component: lazy(() => import("homepage/components/singlePages/contact")) },
            //{ path: "/publications", component: lazy(() => import("homepage/components/singlePages/publications")) },

            // Knowledge
            //{ path: "/knowledge", component: lazy(() => import("homepage/components/knowledge")) },
            //{ path: "/knowledge/events", component: lazy(() => import("homepage/components/knowledge/events")) },
            {
                path: "/knowledge/glossary",
                component: () => (
                    <MetaProvider>
                        <Glossary></Glossary>
                    </MetaProvider>
                ),
            },
            {
                path: "/knowledge/blog",
                component: () => (
                    <MetaProvider>
                        <Blog></Blog>
                    </MetaProvider>
                ),
            },
            {
                path: "/knowledge/blog/:entryName",
                component: () => (
                    <MetaProvider>
                        <ShowBlog></ShowBlog>
                    </MetaProvider>
                ),
            },

            // Products
            //{ path: "/products", component: lazy(() => import("homepage/components/products")) },
            {
                path: "/products/talents",
                component: () => (
                    <MetaProvider>
                        <Talents></Talents>
                    </MetaProvider>
                ),
            },
            //{ path: "/products/network", component: lazy(() => import("homepage/components/products/network")) },

            // Other
            {
                path: "/index",
                component: () => (
                    <MetaProvider>
                        <Home></Home>
                    </MetaProvider>
                ),
            },
            {
                path: "/about_me",
                component: () => (
                    <MetaProvider>
                        <AboutMe></AboutMe>
                    </MetaProvider>
                ),
            },
            {
                path: "/",
                component: () => (
                    <MetaProvider>
                        <Home></Home>
                    </MetaProvider>
                ),
            },
            {
                path: "/*",
                component: () => (
                    <MetaProvider>
                        <NotFound></NotFound>
                    </MetaProvider>
                ),
            },
        ],
    },
];
