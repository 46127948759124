import { faBars, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "@solidjs/router";
import { theme } from "const/theme";
import Fa from "styles/fa";
import { Show, createMemo, createSignal, onCleanup, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import { css } from "solid-styled-components";
import { FlexAuto, StyledColumns, StyledRows } from "styles/layout";
import { P1 } from "styles/text";
import { LangA as A, LangA } from "utils/router";
import getText from "text/homepage/header";
import { createTextObject } from "utils/content";
import { FloatingContainer } from "styles/floating";
import { CleanButton, CleanA } from "styles/interface";
import { CompressedImage } from "styles/other";

const HeaderStyle = css({
    maxWidth: theme.spacing.page.header,
    padding: `${theme.spacing.gap.normal} ${theme.spacing.gap.normal}`,
});

const ActiveLink = css({
    "& > *": {
        transition: "all 0.2s ease-out",
    },
    "&.active > *": {
        color: theme.colors.main,
        display: "initial",
    },
    "&:hover > *": {
        color: theme.colors.mainLight,
    },
    "&.active:hover > *": {
        color: theme.colors.mainLight,
    },
    "@media (max-width: 720px)": {
        display: "none",
    },
});

const SmallDeviceClass = css({
    "@media (min-width: 721px)": {
        display: "none",
    },
});

const Component = () => {
    const text = createTextObject(getText);

    const [showMenu, setShowMenu] = createSignal(false);

    const location = useLocation();

    const params = useParams();

    const langMemo = createMemo(() => {
        if (params.lang) return "/" + params.lang;
        return "";
    });

    const match = createMemo(() => {
        if (location.pathname == langMemo() + "/" || location.pathname == langMemo() + "/index") return "";
        if (location.pathname.startsWith(langMemo() + "/products")) return "products";
        if (location.pathname.startsWith(langMemo() + "/knowledge")) return "knowledge";
        return "";
    });

    let resizeListener = () => {
        if (window.innerWidth > 720 && showMenu()) setShowMenu(false);
    };

    onMount(() => {
        if (isServer == false) window.addEventListener("resize", resizeListener);
    });

    onCleanup(() => {
        if (isServer == false) window.removeEventListener("resize", resizeListener);
    });

    return (
        <StyledRows
            style={{ position: "sticky", top: "0", "background-color": "white", "border-bottom": `2px solid ${theme.colors.brighter}`, "z-index": 100 }}
        >
            <StyledColumns justifyContent="center">
                <StyledColumns flex="1 1 0px" gap="big" justifyContent="space-between" class={HeaderStyle}>
                    <LangA href="/">
                        <StyledColumns gap>
                            <img src="/assets/img/logo.svg" style={{ height: "20px", display: "block" }}></img>
                            <img src="/assets/img/word-logo.svg" style={{ height: "20px", display: "block" }}></img>
                        </StyledColumns>
                    </LangA>

                    <LangA href="/" class={ActiveLink} end>
                        <P1 color="black">{text()?.home}</P1>
                    </LangA>

                    <FloatingContainer
                        class={ActiveLink}
                        options={{ interactive: true }}
                        floatingElement={
                            <StyledRows style={{ padding: "3px" }} gap="small">
                                <CleanA padding href="/knowledge/blog">
                                    <P1 color="black">{text()?.blog}</P1>
                                </CleanA>
                                <CleanA padding href="/knowledge/glossary">
                                    <P1 color="black">{text()?.glossary}</P1>
                                </CleanA>
                                {/** 
                                <CleanA padding href="/knowledge/events">
                                    <P1 color="black">{text()?.events}</P1>
                                </CleanA>
                                */}
                            </StyledRows>
                        }
                    >
                        <CleanButton classList={{ [ActiveLink]: true, active: match() == "knowledge" }}>
                            <P1>{text()?.knowledge}</P1>
                        </CleanButton>
                    </FloatingContainer>


                    <FloatingContainer
                        class={ActiveLink}
                        options={{ interactive: true }}
                        floatingElement={
                            <StyledRows style={{ padding: "3px" }} gap="small">
                                <CleanA padding href="/products/talents">
                                    <P1 color="black">{text()?.talents}</P1>
                                </CleanA>
                                {/** 
                                <CleanA padding href="/products/network">
                                    <P1 color="black">{text()?.network}</P1>
                                </CleanA>
                                 */}
                            </StyledRows>
                        }
                    >
                        <CleanButton classList={{ [ActiveLink]: true, active: match() == "products" }}>
                            <P1>{text()?.products}</P1>
                        </CleanButton>
                    </FloatingContainer>

                    {/**
                    <LangA href="/publications" class={ActiveLink}>
                        <P1 color="black">{text()?.publications}</P1>
                    </LangA>
                    */}

                    <LangA href="/about_me" class={ActiveLink}>
                        <P1 color="black">{text()?.aboutMe}</P1>
                    </LangA>

                    <FlexAuto class={SmallDeviceClass}></FlexAuto>
                    <StyledColumns gap="big">
                        <FloatingContainer
                            options={{ interactive: true }}
                            floatingElement={
                                <StyledRows style={{ padding: "3px" }} gap="small">
                                    <CleanA
                                        ignoreLang
                                        padding
                                        href={langMemo() ? location.pathname.replace(langMemo(), "") : location.pathname}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        <P1 color="black">Deutsch</P1>
                                    </CleanA>
                                    <CleanA
                                        ignoreLang
                                        padding
                                        href={langMemo() ? location.pathname.replace(langMemo(), "/en") : "/en" + location.pathname}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        <P1 color="black">English</P1>
                                    </CleanA>
                                </StyledRows>
                            }
                        >
                            <span style={{ cursor: "pointer" }} onClick={() => setShowMenu(false)}>
                                <Fa scale={1.3} color="black" icon={faLanguage}></Fa>
                            </span>
                        </FloatingContainer>
                        <span style={{ cursor: "pointer" }} class={SmallDeviceClass} onClick={() => setShowMenu(!showMenu())}>
                            <Fa color="black" icon={faBars}></Fa>
                        </span>
                    </StyledColumns>
                </StyledColumns>
            </StyledColumns>

            <Show when={showMenu()}>
                <StyledRows padding backgroundColor="white" fullWidth style={{ position: "absolute", top: "100%" }} gap="big" class={HeaderStyle}>
                    <LangA href="/" end onClick={() => setShowMenu(false)}>
                        <P1 color="black">{text()?.home}</P1>
                    </LangA>
                    <FloatingContainer
                        options={{ interactive: true }}
                        floatingElement={
                            <StyledRows style={{ padding: "3px" }} gap="small">
                                <CleanA padding href="/knowledge/blog" onClick={() => setShowMenu(false)}>
                                    <P1 color="black">{text()?.blog}</P1>
                                </CleanA>
                                <CleanA padding href="/knowledge/glossary" onClick={() => setShowMenu(false)}>
                                    <P1 color="black">{text()?.glossary}</P1>
                                </CleanA>
                            </StyledRows>
                        }
                    >
                        <CleanButton>
                            <P1 color="black">{text()?.knowledge}</P1>
                        </CleanButton>
                    </FloatingContainer>
                    <FloatingContainer
                        options={{ interactive: true }}
                        floatingElement={
                            <StyledRows style={{ padding: "3px" }} gap="small">
                                <CleanA padding href="/products/talents" onClick={() => setShowMenu(false)}>
                                    <P1 color="black">{text()?.talents}</P1>
                                </CleanA>
                            </StyledRows>
                        }
                    >
                        <CleanButton>
                            <P1 color="black">{text()?.products}</P1>
                        </CleanButton>
                    </FloatingContainer>
                    <LangA href="/about_me" onClick={() => setShowMenu(false)}>
                        <P1 color="black">{text()?.aboutMe}</P1>
                    </LangA>
                </StyledRows>
            </Show>
        </StyledRows>
    );
};

export default Component;
