import { useParams } from "@solidjs/router";
import { Accessor, createMemo, createResource } from "solid-js";

export function createTextObject<T>(importFunction: (lang: string) => Promise<T>): Accessor<T | undefined> {
    const params = useParams();

    const langMemo = createMemo(() => {
        return params.lang ?? "";
    });

    const [obj] = createResource(langMemo, async (lang) => {
        const mainLang = await importFunction("de");
        if (lang) {
            const other = await importFunction(lang);
            return { ...mainLang, ...other };
        } else {
            return mainLang;
        }
    });

    const objMemo = createMemo(() => {
        return obj();
    });

    return objMemo;
}