const colors = {
    main: "#6569ae",
    mainLight: "#b3b9dd",
    mainDark: "#00264a",

    second: "#70c1a7",
    secondLight: "#96ccb5",
    secondDark: "#1e513f",

    third: "#f5be69",
    thirdLight: "#feb96c",
    thirdDark: "#fe713b",

    black: "#000000",
    darker: "#262626",
    dark: "#3f3f3f",

    white: "#FFFFFF",
    brighter: "#EDEDED",
    bright: "#DBDBDB",
    gray: "#808080",

    error: "#c75d5d",
};

export const theme = Object.freeze({
    colors: colors,
    spacing: {
        gap: { normal: "10px", big: "20px", bigger: "40px", biggest: "60px", small: "5px" },
        page: { max: "1000px", mobile: "720px", header: "1200px" },
        popupPadding: "0.25em 0.5em",
    },
    radius: {
        default: "5px",
    },
    fonts: {
        size: {
            normal: "15px",
            small: "13px",
            big: "20px",
            bigger: "28px",
            biggest: "45px",
        },
    },
});

/**
const colors = {
    darker: "#16202A",
    dark: "#2B3547",
    main: "#50C878",
    second: "#ff9747",
    white: "#FFFFFF",
    black: "#000000",
    brighter: "#F5F5F5",
    bright: "#E8E2D5",
};
 */
