import { A, AnchorProps, NavigateOptions, useNavigate, useParams } from "@solidjs/router";
import { createMemo } from "solid-js";
import { languages } from "const";
import { MatchFilters } from "@solidjs/router/dist/types";

export const LangA = (props: AnchorProps & { ignoreLang?: boolean;}) => {
    const params = useParams();

    const lang = createMemo(() => {
        return params.lang;
    });

    return (
        <A
            {...props}
            href={!props.ignoreLang && props.href.startsWith("/") && lang() != undefined ? `/${lang()}${props.href}` : props.href}
        ></A>
    );
};

export const useNavigateLang = () => {
    const navigate = useNavigate();
    const params = useParams();
    const lang = createMemo(() => {
        return params.lang;
    });
    return (to: string, options?: Partial<NavigateOptions<unknown>>) => {
        navigate(to.startsWith("/") && lang() != undefined ? `/${lang()}${to}` : to, options);
    };
};

export const langFilter: MatchFilters = {
    lang: languages,
};
