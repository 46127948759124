import { theme } from "const/theme";
import { Accessor, JSX, createEffect, createSignal, splitProps } from "solid-js";
import { Portal } from "solid-js/web";
import { AsProps, css } from "solid-styled-components";
import { useTippy } from "solid-tippy";
import { Instance, Props } from "tippy.js";
import clientOnly from "utils/clientOnly";

const BoxStyle = css({
    backgroundColor: "white",
    borderRadius: theme.radius.default,
    border: `1px solid ${theme.colors.bright}`,
});

const FloatingPortal = clientOnly(
    (props: {
        floatingElement?: JSX.Element;
        options?: Partial<Props>;
        children?: JSX.Element;
        disabled?: boolean;
        hidden?: boolean;
        onInstance?: (instance: Instance<Props> | undefined) => void;
        anchor: Accessor<Element | undefined>;
    }) => {
        const floatingContainer = (() => {
            let ret = document.createElement("div");
            ret.className = BoxStyle;
            return ret;
        })();

        const instance = useTippy(props.anchor, {
            disabled: props.disabled,
            hidden: props.hidden != undefined ? props.hidden : true,
            props: {
                ...{
                    arrow: false,
                    placement: "bottom-start",
                    delay: 0,
                    animation: false,
                    offset: [0, 3],
                    trigger: "mouseenter click focus",
                },
                ...props.options,
                ...{
                    content: floatingContainer,
                },
            },
        });

        createEffect(() => {
            if (props.onInstance) {
                props.onInstance(instance())
            }
        })

        return <Portal mount={floatingContainer}>{props.floatingElement}</Portal>;
    }
);

export const FloatingContainer = (
    props: {
        floatingElement?: JSX.Element;
        options?: Partial<Props>;
        children?: JSX.Element;
        disabled?: boolean;
        hidden?: boolean;
        onInstance?: (instance: Instance<Props> | undefined) => void;
    } & JSX.HTMLAttributes<HTMLDivElement> &
        AsProps
) => {
    const [local, divProps] = splitProps(props, ["floatingElement", "options", "disabled", "hidden", "onInstance"]);
    const [anchor, setAnchor] = createSignal<Element>();

    return (
        <div {...divProps} style={{ cursor: "pointer" }}>
            <div ref={setAnchor}>{props.children}</div>
            <FloatingPortal {...local} anchor={anchor}></FloatingPortal>
        </div>
    );
};
